jQuery(document).ready( function( $ ) {
	'use strict';

	var doc_height           = $(document).height(),
		window_height        = $(window).height(),
		window_width         = $(window).innerWidth(),
		footer               = $('.site-footer'),
		footer_height        = footer.height(),
		animation_box        = $('.header.animation-box'),
		intro_height         = $('.intro-page').outerHeight(),
		footer_animation_box = $('.footer.animation-box'),
		footer_border_side   = window_width * 0.125 + 30,
		footer_animate_start = doc_height - 1.5 * window_height;

	if(footer.length > 0 && footer.offset().top < doc_height - window_height) {
		footer_animate_start = footer.offset().top - 0.5 * window_height;
	}

	var	header_border_top   = 0.12 * window_width,
		header_border_left = (window_width - (0.815 * window_width * 0.75 - 60)) / 2,
		header_border_right = (window_width - (0.815 * window_width * 0.75 - 60)) / 2;

	if($('.intro-page').hasClass('single-project-intro-page')) {
		header_border_left  = window_width * 0.04375 + 30 + (0.9125 * window_width * 0.25);
		header_border_right = window_width * 0.04375 + 30;
	}

	$(window).on('load resize', function() {
		doc_height         = $(document).height();
		window_height      = $(window).height();
		window_width       = $(window).innerWidth();
		footer_height      = footer.height();
		header_border_top  = 0.12 * window_width;
		intro_height       = $('.intro-page').outerHeight();
		footer_border_side  = window_width * 0.125 + 30;
		footer_animate_start = doc_height - 1.5 * window_height;

		if(footer.length > 0 && footer.offset().top < doc_height - window_height) {
			footer_animate_start = footer.offset().top - 0.5 * window_height;
		}

		header_border_left = (window_width - (0.815 * window_width * 0.75 - 60)) / 2;
		header_border_right = (window_width - (0.815 * window_width * 0.75 - 60)) / 2;

		if($('.intro-page').hasClass('single-project-intro-page')) {
			header_border_left  = window_width * 0.04375 + 30 + (0.9125 * window_width * 0.25);
			header_border_right = window_width * 0.04375 + 30;
		}
	});

	function procent (reverse, val_start, val_end, change_el) {
		var k = 0,
			val_start_proc = val_start/window_height,
			val_end_proc = val_end/window_height;
		if(reverse === true) {
			if(val_start_proc <= change_el && change_el <= val_end_proc) {
				k = 1 - (1/(val_end_proc - val_start_proc) * (change_el - val_start_proc));
			} else if(val_end_proc < change_el) {
				k = 0;
			} else {
				k = 1;
			}
		} else {
			if(val_start_proc <= change_el && change_el <= val_end_proc) {
				k = 1 / (val_end_proc - val_start_proc) * (change_el - val_start_proc);
			} else if(val_end_proc < change_el) {
				k = 1;
			} else {
				k = 0;
			}
		}
		return k;
	}

	$(window).on('scroll resize', function () {
		if(991 < window_width) {
			var scroll_pos = $(window).scrollTop();
			var scroll_pos_window = scroll_pos / window_height,
				scroll_pos_intro = scroll_pos / intro_height;

			// Intro Animation

			if(!$('body').hasClass('project-template-small-project')) {
				$('.intro-page-img').css('opacity', procent(true, intro_height * 0.2, intro_height * 0.5, scroll_pos_intro));
				$('.title-box .intro-title, .title-box .intro-subtitle').css({
					'transform': 'translateY(' + (-intro_height * 0.2 * procent(false, intro_height * 0.2, intro_height * 0.6, scroll_pos_intro)) + 'px)',
					'opacity': procent(true, intro_height * 0.2, intro_height * 0.5, scroll_pos_intro)
				});
			}

			animation_box.css({
				'border-top-width': header_border_top * procent(true, 0, intro_height * .5, scroll_pos_intro) + 'px ',
				'border-right-width': header_border_right * procent(true, 0, intro_height * .5, scroll_pos_intro) + 'px',
				'border-bottom-width': header_border_top * procent(true, 0, intro_height * .5, scroll_pos_intro) + 'px ',
				'border-left-width': header_border_left * procent(true, 0, intro_height * .5, scroll_pos_intro) + 'px',
			});

			// Footer Animation

			footer_animation_box.show().css({
				'border-top-width': (window_height - footer_height - 20) * procent(false, footer_animate_start, doc_height - window_height, scroll_pos_window) + 'px',
				'border-left-width': footer_border_side * procent(false, footer_animate_start, doc_height - window_height, scroll_pos_window) + 'px',
				'border-right-width': footer_border_side * procent(false, footer_animate_start, doc_height - window_height, scroll_pos_window) + 'px',
				'border-bottom-width': 117 * procent(false, footer_animate_start, doc_height - window_height, scroll_pos_window) + 'px'
			});
			footer.prev().css('opacity', procent(true, doc_height - 1.5 * window_height, doc_height - window_height, scroll_pos_window));
		}
	});
});

