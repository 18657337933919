$ = jQuery;

$( document ).ready( function( $ ) {
	"use strict";
	$( '.hamburger' ).click( function() {
		let $this = $(this);
		$( this ).toggleClass( 'is-active' );
		$('.main-navigation').toggleClass('menu-open');
	} );

	// Sticky Header

	var header = $('.site-header'),
		height = $('body section:first-of-type').outerHeight();
	if($(window).innerWidth() < 992) {
		if($(window).scrollTop() > height) {
			header.addClass('sticky-header');
		}  else {
			header.removeClass('sticky-header');
		}
		$(document).on('scroll', function() {
			var sTop = $(window).scrollTop();
			if (sTop > height) {
				header.addClass('sticky-header');
			}
			else {
				header.removeClass('sticky-header');
			}
		});
	}

	$('.input-label input[type=text], .input-label input[type=email], .input-label input[type=number], .input-label input[type=date], .input-label input[type=tel], .input-label input[type=time], .input-label textarea, select').on('change', function(){
		if($(this).val() !== '') {
			$(this).closest('label').addClass('focus');
		} else {
			$(this).closest('label').removeClass('focus');
		}
	});
	$('.project-slider').slick({
		rows: 0,
		dots: true,
		arrows: false,
		fade: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 2500,
		cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					adaptiveHeight: true
				}
			}
		]
	});


	$('.kundenstimmen-items').slick({
		rows: 0,
		dots: true,
		arrows: false,
		fade: true,
		speed: 500,
		autoplay: false,
		autoplaySpeed: 2500,
		cssEase: 'cubic-bezier(0.65, 0.05, 0.36, 1)',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					adaptiveHeight: true
				}
			}
		]
	});

	// Tabs

	$('.tab').click(function() {
		var selector = $('#' + $(this).data('id') + '');
		$('.tab').removeClass('active');
		$('.tab-container').hide();
		$(this).addClass('active');
		selector.fadeIn();
	});

	$('.editor p').each(function() {
		if($(this).html() == '&nbsp;') {
			$(this).addClass('empty');
		}
	});

} );
